import { graphql } from "react-apollo"
import gql from "graphql-tag"

const withSolicitation = graphql(
  gql`
    query SolicitationByToken($token: String!) {
      solicitationByToken(token: $token) {
        email
        order_id
        order_data
        id
        review {
          id
        }
      }
    }
  `,
  {
    props: ({ data }) => ({
      loading: data.loading,
      solicitationData: data.solicitationByToken,
    }),
  }
)

export default withSolicitation
