import React from "react"
import PropTypes from "prop-types"
import IconStar from "./IconStar"
import styled from "styled-components"

const StyledStar = styled(IconStar)`
  cursor: pointer;
  stroke: none;
`

const RatingStar = ({ active, handleClick, value, handleHover }) => {
  return (
    <StyledStar
      onMouseEnter={() => handleHover(value)}
      onMouseLeave={() => handleHover(null)}
      active={active}
      color="black"
      size="41px"
      onClick={() => handleClick(value)}
    />
  )
}

RatingStar.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  value: PropTypes.number,
}

export default RatingStar
