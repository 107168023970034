import React from "react"

import Uppy from "@uppy/core"
import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "./uppy.css"
import { isEmpty } from "lodash"

import AwsS3 from "@uppy/aws-s3"
import ms from "ms"

import { Dashboard } from "@uppy/react"
import { Box } from "grommet"

const locale = {
  strings: {
    dropPaste:
      "Upload your photos or videos by dropping them here, or %{browse} for them",
  },
}

class InputFileUpload extends React.Component {
  constructor(props) {
    super(props)
    const {
      form: { setFieldValue, setStatus },
      field: { name, value },
    } = props
    this.uppy = Uppy({
      id: "uppy",
      autoProceed: true,
      allowMultipleUploads: true,
      debug: true,
      restrictions: {
        maxNumberOfFiles: 5,
        allowedFileTypes: [
          "image/*",
          ".jpg",
          ".jpeg",
          ".png",
          ".gif",
          ".mp4",
          ".mov",
          ".heic",
        ],
      },
    })
      .use(AwsS3, {
        limit: 2,
        timeout: ms("1 minute"),
        getUploadParameters: file => {
          // Send a request to our PHP signing endpoint.
          return fetch("/.netlify/functions/s3-sign", {
            method: "post",
            // Send and receive JSON.
            headers: {
              accept: "application/json",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              filename: file.name,
              contentType: file.type,
            }),
          })
            .then(response => {
              // Parse the JSON response.
              return response.json()
            })
            .then(data => {
              // Return an object in the correct shape.
              return {
                method: data.method,
                url: data.url,
                fields: {},
              }
            })
        },
      })
      .on("complete", result => {
        // SET FORM ENABLED
        setFieldValue(name, [...value, result.successful])
        setStatus({ uploading: false })
      })
      .on("error", result => {
        console.log(result)
      })
      .on("upload", data => {
        // SET FORM DISABLED
        setStatus({ uploading: true })
      })
      .on("file-removed", data => {
        const { currentUploads } = this.uppy.getState()
        if (isEmpty(currentUploads)) {
          setStatus({ uploading: false })
        }
      })
  }

  componentWillUnmount() {
    this.uppy.close()
  }

  render() {
    return (
      <Box>
        <Dashboard
          uppy={this.uppy}
          locale={locale}
          width="100%"
          height="300px"
          proudlyDisplayPoweredByUppy={false}
        />
      </Box>
    )
  }
}

export default InputFileUpload
