import React from "react"

import ReviewApp from "../components/ReviewApp"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import withLocation from "../components/withLocation"

const IndexPage = ({ search }) => (
  <Layout>
    <SEO title="Submit Your Review" />

    <ReviewApp token={search.token} rating={search.rating} />
  </Layout>
)

export default withLocation(IndexPage)
