import gql from "graphql-tag"

const CREATE_REVIEW = gql`
  mutation CreateReview(
    $order_id: String
    $email: String!
    $comments: String
    $rating: Int!
    $recommended: Boolean
    $project: String
    $title: String
    $files: JSON
    $solicitation: ID!
    $customer_id: String!
  ) {
    createReview(
      input: {
        data: {
          order_id: $order_id
          email: $email
          comments: $comments
          rating: $rating
          files: $files
          solicitation: $solicitation
          customer_id: $customer_id
          recommended: $recommended
          project: $project
          title: $title
        }
      }
    ) {
      review {
        id
        is_approved
        createdAt
        rating
        order_id
        files
        customer_id
        project
        title
        recommended
      }
    }
  }
`

export default CREATE_REVIEW
