import React from "react"
import Paragraph from "./Paragraph"

import Heading from "./Heading"
import ResponsiveInnerPad from "./ResponsiveInnerPad"

const ReviewFormHeader = () => (
  <>
    <ResponsiveInnerPad
      border={{
        color: "black",
        size: "small",
        side: "bottom",
      }}
    >
      <Heading level={1} margin={{ bottom: "medium" }}>
        We'd love your feedback. How'd it go?
      </Heading>
      <Paragraph>
        As a thank you for your review, a donation of $5 will be made to our
        charity partner the International Rescue Committee.
      </Paragraph>
    </ResponsiveInnerPad>
  </>
)

export default ReviewFormHeader
