import React from "react"
import PropTypes from "prop-types"
import { get, isNil } from "lodash/fp"
import { Box, Paragraph } from "grommet"

import Heading from "./Heading"
import ReviewForm from "./ReviewForm"
import ConstrainedContainer from "./ConstrainedContainer"
import ResponsiveInnerPad from "./ResponsiveInnerPad"
import { compose } from "react-apollo"
import withSolicitation from "../queries/withSolicitation"

const ReviewApp = ({ loading, solicitationData, rating }) => {
  const solicitation = solicitationData
  const needsReview =
    !isNil(get("id", solicitation)) && isNil(get("review.id", solicitation))
  const notFound = !loading && !solicitation
  return (
    <ConstrainedContainer>
      {loading && (
        <ResponsiveInnerPad>
          <Heading level={2}>Loading...</Heading>
        </ResponsiveInnerPad>
      )}
      {needsReview ? (
        <ReviewForm
          order_id={get("order_id", solicitation)}
          email={get("email", solicitation)}
          rating={rating && parseInt(rating)}
          solicitation={get("id", solicitation)}
          order_data={get("order_data", solicitation)}
          customer_id={String(
            get("user_id", solicitation) ||
              get("order_data.customer.id", solicitation)
          )}
        />
      ) : (
        <>
          {!loading && !notFound && (
            <Box pad={{ horizontal: "large", vertical: "large" }}>
              <Heading level={3}>Ooops!</Heading>
              <Paragraph>
                Looks like a review was already submitted for this order. If you
                think there has been an error please shoot us a note at
                hello@backdrophome.com.
              </Paragraph>
            </Box>
          )}
        </>
      )}
      {!loading && notFound && (
        <Box pad={{ horizontal: "large", vertical: "large" }}>
          <Heading level={3}>Ooops!</Heading>
          <Paragraph>
            We couldn't find your order to review. Shoot us a note at
            hello@backdrophome.com.
          </Paragraph>
        </Box>
      )}
    </ConstrainedContainer>
  )
}

ReviewApp.propTypes = {
  token: PropTypes.string,
}

export default compose(withSolicitation)(ReviewApp)
