import React from "react"
import PropTypes from "prop-types"
import { compose, graphql } from "react-apollo"
import { withFormik, Field } from "formik"
import { get } from "lodash/fp"
import styled from "styled-components"
import { Box, TextInput, TextArea, Button, Text } from "grommet"

import ReviewFormHeader from "./ReviewFormHeader"
import OrderInfo from "./OrderInfo"
import InputStarRating from "./InputStarRating"
import InputFileUpload from "./InputFileUpload"
import InputRadioButtons from "./InputRadioButtons"
import FormFieldStep from "./FormFieldStep"
import Paragraph from "./Paragraph"
import ResponsiveInnerPad from "./ResponsiveInnerPad"
import CREATE_REVIEW from "../queries/createReview"

import isServicesOrder from "../lib/isServicesOrder"

const SubmitButton = styled(Button)`
  @media (max-width: 411px) {
    width: 100%;
  }
`

async function handleSubmit(payload, { props, setSubmitting, setStatus }) {
  const {
    order_id,
    email,
    rating,
    comments,
    recommended,
    files,
    customer_id,
    title,
    project,
  } = payload
  const { createReview } = props
  try {
    await createReview({
      variables: {
        order_id,
        email,
        rating,
        title,
        project,
        comments,
        recommended: recommended === "true",
        files,
        customer_id,
      },
    })
    window.location.href = "/thanks"
  } catch (e) {
    setSubmitting(false)
  } finally {
  }
}

const ReviewForm = ({
  order_id,
  email,
  title,
  project,
  rating,
  recommended,
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  status,
  isSubmitting,
  isValid,
  setFieldTouched,
  order_data,
}) => (
  <Box>
    <form onSubmit={handleSubmit}>
      {errors.name && <div id="feedback">{errors.name}</div>}

      <div>
        <ReviewFormHeader />

        <OrderInfo data={order_data} />
        <ResponsiveInnerPad
          pad={{ horizontal: "large", top: "medium" }}
          style={{ paddingBottom: "0" }}
        >
          <FormFieldStep step={1} label="Overall rating" required>
            <Field name="rating" component={InputStarRating} />
          </FormFieldStep>
          <FormFieldStep step={2} label="Review title">
            <TextInput
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              size="medium"
              style={{ fontSize: "16px" }}
              placeholder="Share your thoughts in one sentence"
              focusIndicator={false}
            />
          </FormFieldStep>
          <FormFieldStep step={3} label="Tell us more">
            <TextArea
              name="comments"
              onChange={handleChange}
              onBlur={handleBlur}
              size="xlarge"
              style={{ fontSize: "16px", height: "160px" }}
              focusIndicator={false}
              placeholder="Tell us how it went. Did you enjoy the experience? Do you love the color? How was the quality of the product?"
            />
          </FormFieldStep>
          <FormFieldStep
            step={4}
            label={
              isServicesOrder(order_data)
                ? "What did you have painted?"
                : "What did you paint?"
            }
          >
            <TextInput
              name="project"
              onChange={handleChange}
              onBlur={handleBlur}
              size="medium"
              style={{ fontSize: "16px" }}
              placeholder={`Tell us what room or space you ${
                isServicesOrder(order_data) ? "had " : ""
              }painted`}
              focusIndicator={false}
            />
          </FormFieldStep>
          <FormFieldStep
            step={5}
            label="Bottom line, would you recommend Backdrop to a friend?"
          >
            <div style={{ maxWidth: "350px" }}>
              <Field
                id="recommended"
                name="recommended"
                options={[
                  {
                    name: "Yes",
                    label: <Text>Yes</Text>,
                    id: "yes",
                    value: "true",
                  },
                  {
                    name: "No",
                    label: <Text>No</Text>,
                    id: "no",
                    value: "false",
                  },
                ]}
                size="large"
                component={InputRadioButtons}
              />
            </div>
          </FormFieldStep>
          <FormFieldStep step={6} label="Upload your photos or videos">
            <Field name="files" component={InputFileUpload} />
          </FormFieldStep>

          <Field name="customer_id" type="hidden" />

          <Box align="center">
            <SubmitButton
              label="Submit Your Review"
              type="submit"
              hoverIndicator="background"
              primary
              disabled={!isValid || get("uploading", status) || isSubmitting}
            />
            <Paragraph textAlign="center" margin="medium">
              All submitted reviews are subject to the terms set forth in our
              Terms of Use. Have something else to say? To share feedback with
              us about anything else, please contact us at
              hello@backdrophome.com.
            </Paragraph>
          </Box>
        </ResponsiveInnerPad>
      </div>
    </form>
  </Box>
)

ReviewForm.propTypes = {
  order_id: PropTypes.string,
  order_data: PropTypes.object,
  email: PropTypes.string.isRequired,
  rating: PropTypes.number,
}

export default compose(
  graphql(CREATE_REVIEW, {
    name: "createReview",
  }),
  withFormik({
    mapPropsToValues: ({
      order_id,
      rating,
      title,
      project,
      email,
      solicitation,
      customer_id,
      recommended,
    }) => {
      return {
        order_id,
        rating,
        title,
        project,
        email,
        solicitation,
        customer_id,
        recommended: "true",
        files: [],
      }
    },
    validate: (values, props) => {
      const errors = {}
      if (!values.rating) {
        errors.rating = "Required"
      }
      return errors
    },
    handleSubmit: handleSubmit,
    displayName: "Review",
    isInitialValid: attrs => {
      return attrs.rating ? true : false
    },
  })
)(ReviewForm)
