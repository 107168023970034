import React, { Fragment } from "react"
import { Box, Heading, Text } from "grommet"
import styled from "styled-components"

import ResponsiveInnerPad from "./ResponsiveInnerPad"
import ImageSlider from "./ImageSlider"

const ImageContainer = styled.div`
  padding: ${p => (p.isService ? "10px" : "20px")};
`

const SlideContainer = styled.div`
  text-align: center;
  align-self: flex-end;
  img {
    width: 100%;
    max-width: 148px;
  }

  @media only screen ${p =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    span {
      font-size: 9px;
    }
  }
`

const ServicesWrapper = styled(Box)`
  margin: 16px 8px;
  max-width: 100%;
`

const ServicesInnerWrapper = styled(Box)`
  border: 2px solid black;
  max-width: 200px;
  margin: 0 auto;
  @media only screen ${p =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    border-width: 2px;
  }
`

const TitleText = styled(Text)`
  text-transform: uppercase;
`

const SliderContainer = styled.div``

const sliderConfig = {
  loop: false,
  centeredSlides: false,
  slidesPerView: 4,
  spaceBetween: 32,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 3,
      spaceBetween: 14,
      centeredSlides: false,
    },
    560: {
      slidesPerView: 3,
      spaceBetween: 14,
      centeredSlides: false,
    },
  },
}

const OrderInfo = ({ data }) => {
  const { order_number, line_items = [] } = data
  return (
    <Box
      border={{ size: "small", side: "bottom", color: "black" }}
      pad={{ bottom: "medium" }}
    >
      <ResponsiveInnerPad paddingBottom="0">
        <Heading level={3}>Order No. #{order_number}</Heading>
      </ResponsiveInnerPad>
      <SliderContainer>
        <ImageSlider params={sliderConfig}>
          {line_items.map(x => {
            const Wrapper = x.custom ? ServicesWrapper : Fragment
            const InnerWrapper = x.custom ? ServicesInnerWrapper : Fragment
            return (
              <SlideContainer key={x.id}>
                {x.image_url && (
                  <Wrapper>
                    <InnerWrapper>
                      <ImageContainer isService={x.custom}>
                        <img alt={x.title} src={x.image_url} />
                      </ImageContainer>
                    </InnerWrapper>
                  </Wrapper>
                )}
                <div>
                  <TitleText size="medium">{x.title}</TitleText>
                </div>
              </SlideContainer>
            )
          })}
        </ImageSlider>
      </SliderContainer>
    </Box>
  )
}

export default OrderInfo
