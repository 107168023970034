import React, { useState } from "react"
import RatingStar from "./RatingStar"
import { Box } from "grommet"

const InputStarRating = ({ form, field, max = 5 }) => {
  const [hoverState, setHovered] = useState(false)
  const stars = new Array(max).fill("star")
  return (
    <Box direction="row" gap="xsmall">
      {stars.map((x, i) => (
        <RatingStar
          value={i + 1}
          active={hoverState ? i + 1 <= hoverState : i + 1 <= field.value}
          key={`${field.name}-star-${i + 1}`}
          handleClick={val => form.setFieldValue(field.name, val)}
          handleHover={setHovered}
        />
      ))}
    </Box>
  )
}

export default InputStarRating
