import { map, lowerCase, indexOf, some } from "lodash/fp"

const isServicesOrder = order_data => {
  const { line_items, tags } = order_data

  const isTagged = indexOf("services", map(x => lowerCase(x), tags)) > -1
  const hasCustomLineItem = some({ custom: true }, line_items)

  return isTagged && hasCustomLineItem
}

export default isServicesOrder
