import React from "react"
import PropTypes from "prop-types"
import { RadioButtonGroup } from "grommet"

const InputRadioButtons = ({ options, form, field }) => {
  return (
    <RadioButtonGroup
      name="doc"
      options={options}
      value={field.value}
      onChange={event => form.setFieldValue(field.name, event.target.value)}
    />
  )
}

InputRadioButtons.propTypes = {
  options: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
}

export default InputRadioButtons
