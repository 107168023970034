import React from "react"
import { Box, Text } from "grommet"
import styled from "styled-components"

const stepSize = 1.8

const StepContainer = styled(Box)`
  width: ${stepSize}em;
  @media (max-width: 768px) {
    padding-top: 0.3em;
    width: ${stepSize / 1.2}em;
    font-size: 0.9em;
  }
`
const StepCircle = styled(Box)`
  height: ${stepSize}em;
  @media (max-width: 768px) {
    height: ${stepSize / 1.2}em;
    span {
      font-size: 0.9em;
    }
  }
`

const FormFieldStep = ({ step, label, children, required }) => {
  return (
    <Box direction="row" gap="small" margin={{ bottom: "medium" }} flex="grow">
      <StepContainer pad={{ top: "0.1em" }} style={{ minWidth: "24px" }}>
        <StepCircle
          height={`${stepSize}em`}
          justify="center"
          align="center"
          round="full"
          background="black"
        >
          <Text weight="bold">{step}</Text>
        </StepCircle>
      </StepContainer>
      <Box justify="start" flex={{ grow: 1, shrink: 1 }} direction="column">
        <Text
          color="black"
          weight={500}
          size="large"
          margin={{ bottom: "small" }}
        >
          {label}
        </Text>
        <div>{children}</div>
      </Box>
    </Box>
  )
}

export default FormFieldStep
