import React, { useState, useEffect } from "react"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"
import { merge } from "lodash/fp"
import styled from "styled-components"

const StyledContainer = styled.div`
  height: 100%;

  .swiper-container {
    height: 100%;
  }
  .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.4;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
  .swiper-pagination-bullet-active {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    opacity: 1;
  }
  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    background-size: contain;
  }
  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    background-size: contain;
  }

  @media only screen ${p =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    .swiper-button-next,
    .swiper-button-prev {
      max-width: 10px;
    }
  }
`

const ImageSlider = ({ children, params, activeSlide }) => {
  const [swiper, updateSwiper] = useState(null)
  const defaultParams = {
    loop: false,
    centeredSlides: false,
    slidesPerView: 3,
    spaceBetween: 20,
  }

  useEffect(() => {
    if (swiper && activeSlide !== false) {
      swiper.slideTo(activeSlide, 0)
    }
  })

  const mergedParams = merge(defaultParams, params)

  return (
    <StyledContainer>
      <Swiper getSwiper={updateSwiper} {...mergedParams}>
        {children}
      </Swiper>
    </StyledContainer>
  )
}

ImageSlider.propTypes = {}

export default ImageSlider
